<template>
    <div>
        <a-row class="margin_bottom" justify="center" type="flex">
            <a-col :span="18">
                <DisplayAdherentInfo :user="user"/>
            </a-col>
        </a-row>

        <a-tabs default-active-key="1">
            <a-tab-pane key="1">
        <span slot="tab">
          <a-icon theme="twoTone" two-tone-color="#52c41a" type="dollar"/>
          Paiements
        </span>
                <br/>
                <a-row class="margin_bottom" justify="space-around" type="flex">
                    <a-col :span="4">
                        <a-button
                                @click="handleAdd"
                                class="editable-add-btn margin_btm"
                                type="primary"
                        >
                            Nouveau Paiement
                        </a-button>
                    </a-col>
                    <a-col :span="4" v-if="!hasAnyPaiement" >
                        <a-button
                                @click="handlePassifPaiementEditorShow"
                                class="editable-add-btn margin_btm"
                                type="primary"
                        >
                            Ajouter paiement passif
                        </a-button>
                    </a-col>
                    <a-col :span="4">
                        <a-button
                                @click="handleCalcul"
                                class="editable-add-btn margin_btm"
                                type="primary"
                        >
                            Calculer crédit
                        </a-button>
                    </a-col>
                    <a-col :offset="2" :span="4" v-if="hasAnyPaiement">
                            Solde : <solde-indicator :balance="getUserBalance" />
                    </a-col>
                </a-row>
                <a-row
                        class="margin_bottom pd-l"
                        justify="start"
                        type="flex"
                >
                    <a-col :span="12">
                          <exercices-to-pay-indicator  v-if="isUserAccepter(user.etat)" :balance="getUserBalance" :exercices="getUserExercicesARegler" />
                    </a-col>
                </a-row>
                <a-row justify="center" type="flex">
                    <a-col :span="22">
                        <ListCotisations
                                :cotisations="cotisations"
                                :is-admin="true"
                                :loading="loading"
                                :pagination="pagination"
                                @delete="handleDelete"
                                @delete_file="handleFileDelete"
                                @edit="handleEdit"
                                @refresh_data="getPaiementsList"
                                @set_pagination="setPagination($event)"
                                @upload_success="handleUploadSuccess"
                                @validate_paiement="togglePaiementValidation($event)"
                        ></ListCotisations>
                    </a-col>
                </a-row>
            </a-tab-pane>
            <a-tab-pane key="2">
        <span slot="tab">
          <a-icon theme="twoTone" two-tone-color="#eb2f96" type="dollar"/>
          Crédits
        </span>
                <br/>
                <br/>
                <a-row type="flex">
                    <a-col :span="24">
                        <ReglementsList
                                :data-source="reglements"
                                :is-admin="true"
                                :loading="loading"
                                :pagination="reglementsPagination"
                                :somme="sommeReglements"
                                @refresh_data="getReglementsList($event)"
                                @set_pagination="setReglementPagination($event)"
                        ></ReglementsList>
                    </a-col>
                </a-row>
            </a-tab-pane>
        </a-tabs>

        <br/>
        <br/>
        <PaiementEditor
                :exercices="exercices"
                :key="cotisationComponentKey"
                :modes_reglement="modes"
                :paiement="selected_paiement"
                :processing="processing"
                :type_paiements="type_paiements"
                :visible="visible"
                @close="handleClose"
                @confirme="handlePiaementSubmi"
                ref="paiementForm"
        />
        <PassifPaiementEditor
                :exercices="exercices"
                :processing="passifPaiementEditorProcessing"
                :visible="passifPaiementEditorVisible"
                @close="handlePassifPaiementEditorClose"
                @submit="handlePassifPaiementEditorSubmit"
                ref="passifPaiementEditor"
        />
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import ListCotisations from "@/components/common/cotisation/CotisationList";
import ReglementsList from "@/components/common/paiement/ReglementsList";
import PaiementEditor from "@/components/common/cotisation/CotisationEditor";
import DisplayAdherentInfo from "@/components/common/UserInfo";
import PassifPaiementEditor from "@/components/common/cotisation/PassifPaiementEditor";
import ExercicesToPayIndicator from '@/components/common/cotisation/ExercicesToPayIndicator';
import SoldeIndicator from '@/components/common/cotisation/SoldeIndicator';

export default {
  name: "PaiementIndex",
  created() {
    this.clearSelectedAdherent();
    this.clearUserBalance();
    this.resetPagination();
    this.setUserId();
    this.getUserReglementsPaiments(this.userID);
    this.getAdditionalData();
  },
    components: {
            ListCotisations,
            PaiementEditor,
            DisplayAdherentInfo,
            ReglementsList,
            PassifPaiementEditor,
            ExercicesToPayIndicator,
            SoldeIndicator
        },
        data() {
            return {
                visible: false,
                passifPaiementEditorVisible: false,
                passifPaiementEditorProcessing: false,
                loading: true,
                processing: false,
                cotisationComponentKey: 0,
                exercice: null,
                userID: null
            };
        },
        computed: {
            ...mapState({
                user: (state) => state.adherents.selected_adherent,
                modes: (state) => state.mode_reglement.modes_reglement,
                exercices: (state) => state.exercices.exercices,
                type_paiements: (state) => state.type_paiements.type_paiements,
                reglements: (state) => state.reglement.reglements,
                calcul_balance: (state) => state.admin_paiements.balance,
                selected_paiement: (state) => state.admin_paiements.selected_paiement,
                cotisations: (state) => state.admin_paiements.cotisations,
                base_url: (state) => state.auth.base_url,
                pagination: (state) => state.admin_paiements.pagination,
                reglementsPagination: (state) => state.reglement.pagination,
            }),
            ...mapGetters({
                existingReglment: "existingAdminCotisation",
                getUserBalance: "getUserBalanceValue",
                getUserExercicesARegler: "getUserBalanceExercicesARegler",
                sommeReglements: "getListPaiementSoldSomme",
            }),
            hasAnyPaiement() {
                return this.cotisations.length;
            }
        },
        methods: {
            getAdditionalData() {
              this.$store.cache.dispatch("fetchExercices")
              this.$store.cache.dispatch("fetchModesReglement")
              this.getTypePaiements()
            },
            setUserId() {
                this.userID = this.$route.params["id"];
            },
            handleCalcul() {
                this.startLoading();
                this.calculateUserBalance(this.userID)
                    .finally(() => this.stopLoading());
            },
            getPaiementColor(solde) {
                return this.isPayeAjour(solde) ? this.GREEN_COLOR : this.RED_COLOR;
            },
            getUserReglementsPaiments() {
                this.getUserById(this.userID)
                    .then(() => {
                        this.getPaiementsList();
                        this.getReglementsList();
                        this.handleCalcul();
                    })
                    .catch(() => this.$_throwAnError());
            },
            handlePassifPaiementEditorSubmit() {
                // let error = false;
             
                this.$refs["passifPaiementEditor"].form.validateFieldsAndScroll((err, values) => {
                    if (!err) {
                        let paiementPassif = {...values}
                        paiementPassif.userId = this.user.id
                        this.startPassifPaiementEditorProcessing();
                        this.addPaiementPassif(paiementPassif)
                            .then(() => {
                                this.stopPassifPaiementEditorProcessing();
                                this.handlePassifPaiementEditorClose();
                                this.getPaiementsList();
                                this.handleCalcul();
                            })
                            .catch(() => {
                                this.$_throwAnError();
                                this.stopPassifPaiementEditorProcessing();
                            });
                    } else {
                        // error = true;
                    }
                });
            },
            showEditor() {
                this.genererateNewPaiementEditorKey();
                this.setModalVisible();
            },
            setModalVisible() {
                this.visible = true;
            },
            setModalInvisible() {
                this.visible = false;
            },
            startLoading() {
                this.loading = true;
            },
            stopLoading() {
                this.loading = false;
            },
            startProcessing() {
                this.processing = true;
            },
            stopProcessing() {
                this.processing = false;
            },
            startPassifPaiementEditorProcessing() {
                this.passifPaiementEditorProcessing = true;
            },
            stopPassifPaiementEditorProcessing() {
                this.passifPaiementEditorProcessing = false;
            },
            handlePassifPaiementEditorClose() {
                this.passifPaiementEditorVisible = false;
            },
            handlePassifPaiementEditorShow() {
                   if (!this.isUserAccepter(this.user.etat)) {
                    this.$_throwAnError("Vous devez d'abord accepter l'adhérent.");
                    return;
                }
                this.passifPaiementEditorVisible = true;
            },
            handleClose() {
                this.closeEditor();
                this.resetSelectedCotisation();
            },
            getReglementsList() {
                this.startLoading();
                this.getReglements({id: this.userID, exercice: this.exercice})
                    .finally(() => this.stopLoading());
            },
            getPaiementsList() {
                this.startLoading();
                this.getCotisationById({id: this.userID, exercice: this.exercice})
                    .finally(() => this.stopLoading());
            },
            handleUploadSuccess() {
                this.getPaiementsList();
            },
            closeEditor() {
                this.visible = false;
            },
            handlePiaementSubmi() {
                let error = false;
                this.$refs["paiementForm"].form.validateFieldsAndScroll((err, values) => {
                    if (!err) {
                        let reglement = {
                            ...values,
                            'user_id': this.user.id,
                            'dt_encaissement': values.dt_encaissement.format("YYYY-MM-DD")
                        }
                        // console.warn(reglement)
                        this.startProcessing();
                        if (this.selected_paiement) {
                            this.update({
                                ...reglement,
                                id: this.selected_paiement.id,
                            })
                                .then(() => {
                                    this.resetAndClean();
                                    this.getPaiementsList();
                                    this.handleCalcul();
                                })
                                .catch(() => {
                                    this.$_throwAnError();
                                    this.stopProcessing();
                                });
                        } else {
                            this.add(reglement)
                                .then(() => {
                                    this.resetAndClean();
                                    this.getPaiementsList();
                                    this.handleCalcul();
                                })
                                .catch(() => {
                                    this.$_throwAnError();
                                    this.stopProcessing();
                                });
                        }
                    } else {
                        error = true;
                    }
                });

                if (!error) {
                    this.current++;
                }
            },
            resetAndClean() {
                this.resetSelectedCotisation();
                this.$refs["paiementForm"].form.resetFields();
                this.$refs["passifPaiementEditor"].form.resetFields();
                this.setModalInvisible();
                this.stopProcessing();
            },
            handleEdit(cotisationId) {
                this.fetchPaiement(cotisationId)
                    .then(() => this.showEditor());
            },
            resetSelectedCotisation() {
                this.clearSelectedPaiement();
            },
            handleAdd() {
                if (!this.isUserAccepter(this.user.etat)) {
                    this.$_throwAnError("Vous devez d'abord accepter l'adhérent.");
                    return;
                }
                this.showEditor();
            },
            genererateNewPaiementEditorKey() {
                this.cotisationComponentKey = this.getRandomInt();
            },
            handleDelete(value) {
                this.delete(value)
                    .then(() => {
                        this.getPaiementsList();
                        this.handleCalcul();
                    });
            },
            togglePaiementValidation(paiement) {
                this.validatePaiement(paiement)
                    .then(() => {
                        this.getPaiementsList();
                        this.handleCalcul();
                    });
            },
            handleFileDelete(value) {
                this.deleteFile(value)
                    .then(() => this.getPaiementsList());
            },
            ...mapActions({
                getUserById: "fetchAdminAdherentsById",
                getCotisationById: "fetchAdminPaiements",
                getReglements: "fetchAdminReglements",
                getTypePaiements: "fetchTypePaiementsWithoutPagination",
                fetchPaiement: "fetchPaiementById",
                calculateUserBalance: "calculateUserBalance",
                validatePaiement: "validatePaiement",
                add: "storePaiement",
                addPaiementPassif: "setUserPaiementPassif",
                update: "updatePaiement",
                delete: "destroyPaiement",
                deleteFile: "destroyPaiementFile",
            }),
            ...mapGetters({
                getCotisationReglementById: "getCotisationsById",
                currentExercice: "getCurrentExercice",
            }),
            ...mapMutations({
                clearSelectedAdherent: "clearSelectedAdherent",
                resetPagination: "setCotisationDefaultPagination",
                setPagination: "setCotisationPagination",
                setReglementPagination: "setReglementPagination",
                clearSelectedPaiement: "clearSelectedPaiement",
                clearUserBalance: "clearUserBalance",
            }),
        },
    };
</script>
<style scoped>
    .margin_btm {
        margin-bottom: 20px;
    }

    .pd-l {
        padding-left: 4%;
    }

    .margin_bottom {
        margin-bottom: 3%;
    }
</style>
